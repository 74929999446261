<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->
      <div class="row">
        <div class="col-xl-12">
          <h1>Nivel de electrificación. {{ this.currentObject.nombre }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              <v-select
                :items="this.$regions"
                item-text="nombre"
                item-value="slug"
                v-model="selected"
                v-on:change="changeComunidad"
              ></v-select>

              <v-spacer></v-spacer>

              <router-link
                to="/autoconsumo"
                class="btn btn-success font-weight-bolder font-size-sm"
                >Volver a los datos nacionales</router-link
              >
            </v-card-title>

            <v-card-text>
              <fusioncharts
                :type="this.currentObject.tipo_mapa"
                width="100%"
                height="500"
                dataFormat="jsonurl"
                :dataSource="
                  '/data/ELC/comunidad/' + this.currentSlug + '_mapa_inp01.json'
                "
              >
              </fusioncharts>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title
              >Grado de electrificación por tarifa (kWh
              anuales/CUPS)</v-card-title
            >
            <v-card-text>
              <fusioncharts
                type="column2d"
                width="100%"
                height="500"
                dataFormat="jsonurl"
                :dataSource="
                  '/data/ELC/comunidad/' + this.currentSlug + '_inp01.json'
                "
              >
              </fusioncharts>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title
              >Medidas de eficiencia energética adoptadas por domésticos
            </v-card-title>

            <v-card-text>
              <fusioncharts
                type="pie3d"
                width="100%"
                height="400"
                dataFormat="jsonurl"
                :dataSource="
                  '/data/ELC/comunidad/' + this.currentSlug + '_inp07.json'
                "
              >
              </fusioncharts>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Peso del coste eléctrico en el consumo energético
              doméstico</v-card-title
            >

            <v-card-text>
              <fusioncharts
                type="pie3d"
                width="100%"
                height="400"
                dataFormat="jsonurl"
                :dataSource="
                  '/data/ELC/comunidad/' + this.currentSlug + '_inp07.json'
                "
              >
              </fusioncharts>
            </v-card-text>
          </v-card>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "dashboard",
  components: {},
  data() {
    return {
      selected: this.$route.params.comunidad,
      selectedVariable:
        "Contribución a la descarbonización del Vehículo Eléctrico",
      selectedYear: "2020",
      selectedOption: "Todos",
      chartColumn: {
        type: "column2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      chartMultipleColumn: {
        type: "mscolumn2d",
        width: "100%",
        height: "400",
        dataFormat: "jsonurl",
      },
      mapSpain: {
        type: "spain",
        width: "100%",
        height: "300",
        dataFormat: "jsonurl",
      },
    };
  },
  computed: {
    currentSlug() {
      return this.$route.params.comunidad;
    },
    currentObject() {
      let selectedRegion = this.$regions.filter((comunidad) => {
        return comunidad.slug == this.$route.params.comunidad;
      });
      return selectedRegion[0];
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    changeComunidad(selected) {
      this.$router.push({
        name: "nivel-electrificacion-comunidad",
        params: { comunidad: selected },
      });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
